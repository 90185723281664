// Background
.bg-primary {
  background-color: #007bff !important
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important
}

.bg-secondary {
  background-color: #6c757d !important
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important
}

.bg-success {
  background-color: #28a745 !important;
  color: #fff;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important
}

.bg-info {
  background-color: #17a2b8 !important;
  color: #fff;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
  color: #fff;
}

.bg-warning {
  background-color: #ffc107 !important;
  color: #fff;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important
}

.bg-danger {
  background-color: #dc3545 !important;
  color: #fff;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important
}

.bg-light {
  background-color: #f8f9fa !important
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important
}

.bg-dark {
  background-color: #343a40 !important
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important
}


.bg-primary {
  background-color: #5d9cec;
  color: #fff !important
}

.bg-primary-light {
  background-color: #8bb8f1;
  color: #fff !important
}

.bg-primary-dark {
  background-color: #2f80e7;
  color: #fff !important
}

.bg-primary small {
  color: inherit
}

.bg-success {
  background-color: #2ecc71;
  color: #fff !important
}

.bg-success-light {
  background-color: #54d98c;
  color: #fff !important
}

.bg-success-dark {
  background-color: #25a25a;
  color: #fff !important
}

.bg-success small {
  color: inherit
}

.bg-info-light {
  background-color: #488fed;
  color: #fff !important
}

.bg-info-dark {
  background-color: #135cbc;
  color: #fff !important
}

.bg-warning-light {
  background-color: #ffab5e;
  color: #fff !important
}

.bg-warning-dark {
  background-color: #f77600;
  color: #fff !important
}

.bg-danger-light {
  background-color: #f47f7f;
  color: #fff !important
}

.bg-danger-dark {
  background-color: #c0392b;
  color: #fff !important
}

.bg-green {
  background-color: #37bc9b;
  color: #fff !important
}

.bg-green-light {
  background-color: #58ceb1;
  color: #fff !important
}

.bg-green-dark {
  background-color: #2b957a;
  color: #fff !important
}

.bg-green small {
  color: inherit
}

.bg-pink {
  background-color: #f532e5;
  color: #fff !important
}

.bg-pink-light {
  background-color: #f763eb;
  color: #fff !important
}

.bg-pink-dark {
  background-color: #e90bd6;
  color: #fff !important
}

.bg-pink small {
  color: inherit
}

.bg-purple {
  background-color: #7266ba;
  color: #fff !important
}

.bg-purple-light {
  background-color: #9289ca;
  color: #fff !important
}

.bg-purple-dark {
  background-color: #564aa3;
  color: #fff !important
}

.bg-purple small {
  color: inherit
}

.bg-inverse {
  background-color: #131e26;
  color: #fff !important
}

.bg-inverse-light {
  background-color: #243948;
  color: #fff !important
}

.bg-inverse-dark {
  background-color: #020304;
  color: #fff !important
}

.bg-inverse small {
  color: inherit
}

.bg-yellow {
  background-color: #fad732;
  color: #fff !important
}

.bg-yellow-light {
  background-color: #fbe164;
  color: #fff !important
}

.bg-yellow-dark {
  background-color: #f3ca06;
  color: #fff !important
}

.bg-yellow small {
  color: inherit
}

.bg-white {
  background-color: #fff;
  color: inherit !important
}

.bg-gray-darker {
  background-color: #232735;
  color: #fff !important
}

.bg-gray-dark {
  background-color: #3a3f51;
  color: #fff !important
}

.bg-gray {
  background-color: #dde6e9;
  color: #515253 !important
}

.bg-gray-light {
  background-color: #e4eaec;
  color: #515253 !important
}

.bg-gray-lighter {
  background-color: #edf1f2;
  color: #515253 !important
}

.bg-transparent {
  background-color: transparent !important
}

.bg-cover {
  background-size: cover
}

// Width
.wd-xl {
  width: 320px;
}

// Paddings
.p {
  padding: 10px !important;
}

.p-lg {
  padding: 15px !important
}

// Margin
.m0 {
  margin: 0 !important
}

.ml0 {
  margin-left: 0 !important
}

.mr0 {
  margin-right: 0 !important
}

.mt0 {
  margin-top: 0 !important
}

.mb0 {
  margin-bottom: 0 !important
}

.m {
  margin: 10px !important
}

.ml,
.mh {
  margin-left: 10px !important
}

.mr,
.mh {
  margin-right: 10px !important
}

.mt,
.mv {
  margin-top: 10px !important
}

.mb,
.mv {
  margin-bottom: 10px !important
}

.m-sm {
  margin: 5px !important
}

.ms-sm,
.mh-sm {
  margin-left: 5px !important
}

.me-sm,
.mh-sm {
  margin-right: 5px !important
}

.mt-sm,
.mv-sm {
  margin-top: 5px !important
}

.mb-sm,
.mv-sm {
  margin-bottom: 5px !important
}

.m-lg {
  margin: 15px !important
}

.ms-lg,
.mh-lg {
  margin-left: 15px !important
}

.me-lg,
.mh-lg {
  margin-right: 15px !important
}

.mt-lg,
.mv-lg {
  margin-top: 15px !important
}

.mb-lg,
.mv-lg {
  margin-bottom: 15px !important
}

.m-xl {
  margin: 30px !important
}

.ms-xl {
  margin-left: 30px !important
}

.me-xl {
  margin-right: 30px !important
}


.mt-xl {
  margin-top: 30px !important;
}

.mt-lg,
.mv-lg {
  margin-top: 15px !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important
}

.mx-xs {
  margin: 0 .2rem !important
}

.mx-sm {
  margin-left: 10px !important;
  margin-right: 10px !important
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important
}

.mx-2 {
  margin: 0 1rem
}

.my-xs {
  margin: .4rem 0 !important
}

.my-sm {
  margin-top: .8rem !important;
  margin-bottom: .8rem !important
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important
}

.my-2 {
  margin: 20px 0 !important
}

.mt-xs {
  margin-top: .3rem
}

.mt-sm,
.mv-sm {
  margin-top: .7rem
}

.mt-1 {
  margin-top: 1rem
}

.mt-negative-10 {
  margin-top: -10px
}

.mt-negative-5 {
  margin-top: -5px
}

.mb-sm,
.mv-sm {
  margin-bottom: .8rem !important
}

.mb-md {
  margin-bottom: 15px !important
}

.ms-xs {
  margin-left: .2rem
}

.ms-sm,
.mh-sm {
  margin-left: .8rem
}

// Colors
.text-muted {
  color: #909fa7 !important;
}

.text-info {
  color: #17a2b8 !important
}

.text-darkk {
  color: #464646;
}

.text-darkk:hover {
  color: #464646;
}

// Text Size
h1,
.h1 {
  font-size: 36px
}

h2,
.h2 {
  font-size: 30px
}

h3,
.h3 {
  font-size: 24px
}

h4,
.h4 {
  font-size: 18px
}

h5,
.h5 {
  font-size: 14px
}

h6,
.h6 {
  font-size: 12px
}

p {
  margin: 0 0 10.5px
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 10.5px;
  margin-bottom: 10.5px;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 10.5px !important;
  margin-bottom: 10.5px
}

// Cards
.card-dark {
  border: 0;
}

.card-dark>.card-header {
  color: #fff;
  background-color: #4a547a;
  border-color: #4a547a
}

.card-default {
  border-top-width: 3px;
  border-color: #cfdbe2;
}

.card-body {
  padding: 15px;
}

.card-header {
  padding: 10px 15px;
}

.card-footer {
  padding: 10px 15px;
}

// Forms
.form-control {
  display: block;
  width: 100%;
  height: 35px;
  padding: 6px 16px;
  font-size: 14px;
  line-height: 1.528571429;
  color: #3a3f51;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dde6e9;
  border-radius: 4px;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
  box-shadow: 0 0 #000 !important;
}

.has-feedback .form-control {
  padding-right: 43.75px;
}

.form-control-feedback {
  position: absolute;
  right: 0;
  z-index: 2;
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  pointer-events: none;
}

textarea.form-control {
  height: auto;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}

// Buttons
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 16px;
  font-size: 13px;
  line-height: 1.528571429;
  border-radius: 4px;
  -webkit-user-select: none;
  user-select: none
}

.btn:hover,
.btn:focus,
.btn.focus {
  color: #333;
  text-decoration: none
}

.btn:active,
.btn.active {
  outline: 0;
  background-image: none;
  box-shadow: inset 0 3px 5px #00000020
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: .65;
  filter: alpha(opacity=65);
  box-shadow: none
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #eaeaea
}

.btn-default:focus,
.btn-default.focus {
  color: #333;
  background-color: #f5f5f5;
  border-color: #d6d6d6
}

.btn-default:hover {
  color: #333;
  background-color: #f5f5f5;
  border-color: #d6d6d6
}

.btn-default:active,
.btn-default.active,
.open>.btn-default.dropdown-toggle {
  color: #333;
  background-color: #f5f5f5;
  border-color: #d6d6d6
}

.btn-default:active:hover,
.btn-default:active:focus,
.btn-default:active.focus,
.btn-default.active:hover,
.btn-default.active:focus,
.btn-default.active.focus,
.open>.btn-default.dropdown-toggle:hover,
.open>.btn-default.dropdown-toggle:focus,
.open>.btn-default.dropdown-toggle.focus {
  color: #333;
  background-color: #f5f5f5;
  border-color: #d6d6d6
}

.btn-default:active,
.btn-default.active,
.open>.btn-default.dropdown-toggle {
  background-image: none
}

.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled.focus,
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #eaeaea
}

.btn-default .badge {
  color: #fff;
  background-color: #333
}

.btn-primary {
  color: #fff;
  background-color: #5d9cec;
  border-color: #5899eb
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #4b91ea;
  border-color: #3483e7
}

.btn-primary:hover {
  color: #fff;
  background-color: #4b91ea;
  border-color: #3483e7
}

.btn-primary:active,
.btn-primary.active,
.open>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #4b91ea;
  border-color: #3483e7
}

.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open>.btn-primary.dropdown-toggle:hover,
.open>.btn-primary.dropdown-toggle:focus,
.open>.btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #4b91ea;
  border-color: #3483e7
}

.btn-primary:active,
.btn-primary.active,
.open>.btn-primary.dropdown-toggle {
  background-image: none
}

.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #5d9cec;
  border-color: #5899eb
}

.btn-primary .badge {
  color: #5d9cec;
  background-color: #fff
}

.btn-success {
  color: #fff;
  background-color: #2ecc71;
  border-color: #2dc86f
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #2abb68;
  border-color: #26a75c
}

.btn-success:hover {
  color: #fff;
  background-color: #2abb68;
  border-color: #26a75c
}

.btn-success:active,
.btn-success.active,
.open>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #2abb68;
  border-color: #26a75c
}

.btn-success:active:hover,
.btn-success:active:focus,
.btn-success:active.focus,
.btn-success.active:hover,
.btn-success.active:focus,
.btn-success.active.focus,
.open>.btn-success.dropdown-toggle:hover,
.open>.btn-success.dropdown-toggle:focus,
.open>.btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #2abb68;
  border-color: #26a75c
}

.btn-success:active,
.btn-success.active,
.open>.btn-success.dropdown-toggle {
  background-image: none
}

.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus {
  background-color: #2ecc71;
  border-color: #2dc86f
}

.btn-success .badge {
  color: #2ecc71;
  background-color: #fff
}

.btn-info {
  color: #fff;
  background-color: #1a73e8;
  border-color: #1770e6
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #166ad8;
  border-color: #135ec1
}

.btn-info:hover {
  color: #fff;
  background-color: #166ad8;
  border-color: #135ec1
}

.btn-info:active,
.btn-info.active,
.open>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #166ad8;
  border-color: #135ec1
}

.btn-info:active:hover,
.btn-info:active:focus,
.btn-info:active.focus,
.btn-info.active:hover,
.btn-info.active:focus,
.btn-info.active.focus,
.open>.btn-info.dropdown-toggle:hover,
.open>.btn-info.dropdown-toggle:focus,
.open>.btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #166ad8;
  border-color: #135ec1
}

.btn-info:active,
.btn-info.active,
.open>.btn-info.dropdown-toggle {
  background-image: none
}

.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus {
  background-color: #1a73e8;
  border-color: #1770e6
}

.btn-info .badge {
  color: #1a73e8;
  background-color: #fff
}

.btn-warning {
  color: #fff;
  background-color: #ff902b;
  border-color: #ff8d26
}

.btn-warning:focus,
.btn-warning.focus {
  color: #fff;
  background-color: #ff8517;
  border-color: #fc7800
}

.btn-warning:hover {
  color: #fff;
  background-color: #ff8517;
  border-color: #fc7800
}

.btn-warning:active,
.btn-warning.active,
.open>.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ff8517;
  border-color: #fc7800
}

.btn-warning:active:hover,
.btn-warning:active:focus,
.btn-warning:active.focus,
.btn-warning.active:hover,
.btn-warning.active:focus,
.btn-warning.active.focus,
.open>.btn-warning.dropdown-toggle:hover,
.open>.btn-warning.dropdown-toggle:focus,
.open>.btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #ff8517;
  border-color: #fc7800
}

.btn-warning:active,
.btn-warning.active,
.open>.btn-warning.dropdown-toggle {
  background-image: none
}

.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus {
  background-color: #ff902b;
  border-color: #ff8d26
}

.btn-warning .badge {
  color: #ff902b;
  background-color: #fff
}

.btn-danger {
  color: #fff;
  background-color: #f05050;
  border-color: #f04b4b
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #ee3d3d;
  border-color: #ec2626
}

.btn-danger:hover {
  color: #fff;
  background-color: #ee3d3d;
  border-color: #ec2626
}

.btn-danger:active,
.btn-danger.active,
.open>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ee3d3d;
  border-color: #ec2626
}

.btn-danger:active:hover,
.btn-danger:active:focus,
.btn-danger:active.focus,
.btn-danger.active:hover,
.btn-danger.active:focus,
.btn-danger.active.focus,
.open>.btn-danger.dropdown-toggle:hover,
.open>.btn-danger.dropdown-toggle:focus,
.open>.btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ee3d3d;
  border-color: #ec2626
}

.btn-danger:active,
.btn-danger.active,
.open>.btn-danger.dropdown-toggle {
  background-image: none
}

.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus {
  background-color: #f05050;
  border-color: #f04b4b
}

.btn-danger .badge {
  color: #f05050;
  background-color: #fff
}

.btn-link {
  color: inherit;
  font-weight: 400;
  border-radius: 0
}

.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  box-shadow: none
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent
}

.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus {
  color: #e4eaec;
  text-decoration: none
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 10px 20px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px
}

.btn-sm,
.calendar-app .fc-button,
.btn-group-sm>.btn {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px
}

.btn-xs,
.btn-group-xs>.btn {
  padding: 1px 5px;
  line-height: 1.5;
  border-radius: 3px
}

.btn-block {
  display: block;
  width: 100%
}

.btn-block+.btn-block {
  margin-top: 5px
}

.btn-sm {
  font-size: 13px;
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: .65;
  filter: alpha(opacity=65);
  box-shadow: none;
}

// HTML Tags
a {
  outline: none !important;
  cursor: pointer;
  color: #5d9cec;
  text-decoration: none;
  background-color: transparent;
}

// Navbar
.wrapper>.aside {
  width: 220px;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 116;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: width .2s cubic-bezier(.35, 0, .25, 1), translate .2s cubic-bezier(.35, 0, .25, 1);
}

.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

@media only screen and (min-width: 768px) {
  .layout-fixed .wrapper>section {
    margin-top: 55px;
    margin-left: 220px;
  }
}

.wrapper>section {
  transition: margin-left .2s cubic-bezier(.35, 0, .25, 1);
}

.sidebar-subnav {
  overflow: hidden;
  transition: height 0.2s ease 0s;
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none
}

.nav:before,
.nav:after {
  content: " ";
  display: table
}

.nav:after {
  clear: both
}

.nav>li {
  position: relative;
  display: block
}

.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px
}

.nav>li>a:hover,
.nav>li>a:focus {
  text-decoration: none;
  background-color: transparent
}

.nav>li.disabled>a {
  color: #e4eaec
}

.nav>li.disabled>a:hover,
.nav>li.disabled>a:focus {
  color: #e4eaec;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
  background-color: transparent;
  border-color: #5d9cec
}

.nav .nav-divider {
  height: 1px;
  margin: 9.5px 0;
  overflow: hidden;
  background-color: #e5e5e5
}

.nav>li>a>img {
  max-width: none
}

.nav-tabs {
  border-bottom: 1px solid #ddd
}

.nav-tabs>li {
  float: left;
  margin-bottom: -1px
}

.nav-tabs>li>a {
  margin-right: 2px;
  line-height: 1.528571429;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0
}

.nav-tabs>li>a:hover {
  border-color: #ddd #ddd #ddd
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
  color: #dde6e9;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default
}

.sidebar-subnav>li>a>em,
.sidebar-subnav>li>.nav-item>em {
  width: 2em;
}

// Spacing
@media only screen and (min-width: 768px) {
  .content-wrapper {
    padding: 22px 20px;
  }
}

.ph {
  padding-left: 10px;
  padding-right: 10px;
}

// Borders

.b0 {
  border-width: 0 !important
}

.bl0 {
  border-left-width: 0 !important
}

.br0 {
  border-right-width: 0 !important
}

.bt0 {
  border-top-width: 0 !important
}

.bb0 {
  border-bottom-width: 0 !important
}

.br,
.b,
.ba {
  border-right: 1px solid rgba(0, 0, 0, .12)
}

.bl,
.b,
.ba {
  border-left: 1px solid rgba(0, 0, 0, .12)
}

.bt,
.b,
.ba {
  border-top: 1px solid rgba(0, 0, 0, .12)
}

.bb,
.b,
.ba {
  border-bottom: 1px solid rgba(0, 0, 0, .12)
}

// Inputs
fieldset {
  padding-bottom: 10px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 10px;
}

fieldset:last-child {
  border-bottom: 0;
}

.list-group {
  line-height: 1.3
}

// Rows and Columns
.col-12,
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12,
.col-xl-3 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px
}

.row {
  margin-left: -15px;
  margin-right: -15px
}

@media (min-width: 1921px) {
  .col-2xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

// Horizontal Line
hr {
  border-top: 1px solid #e4eaec;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 0;
}

// Lists
ul {
  padding-left: 40px;
}

.list-group {
  line-height: 1.3
}

.list-group .list-group-item {
  padding: 10px
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #e4eaec
}

.list-group-item:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px
}

a.list-group-item,
button.list-group-item {
  color: #555
}

a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
  color: #333
}

a.list-group-item:hover,
a.list-group-item:focus,
button.list-group-item:hover,
button.list-group-item:focus {
  text-decoration: none;
  color: #555;
  background-color: #fafbfc
}

button.list-group-item {
  width: 100%;
  text-align: left
}

.list-group-item.disabled,
.list-group-item.disabled:hover,
.list-group-item.disabled:focus {
  background-color: #edf1f2;
  color: #e4eaec;
  cursor: not-allowed
}

.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading {
  color: inherit
}

.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text {
  color: #e4eaec
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #5d9cec;
  border-color: #5d9cec
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading>small,
.list-group-item.active .list-group-item-heading>.small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading>small,
.list-group-item.active:hover .list-group-item-heading>.small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading>small,
.list-group-item.active:focus .list-group-item-heading>.small {
  color: inherit
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
  color: #fff
}

.list-group-item-success {
  color: #2ecc71;
  background-color: #dff0d8
}

a.list-group-item-success,
button.list-group-item-success {
  color: #2ecc71
}

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
  color: inherit
}

a.list-group-item-success:hover,
a.list-group-item-success:focus,
button.list-group-item-success:hover,
button.list-group-item-success:focus {
  color: #2ecc71;
  background-color: #d0e9c6
}

a.list-group-item-success.active,
a.list-group-item-success.active:hover,
a.list-group-item-success.active:focus,
button.list-group-item-success.active,
button.list-group-item-success.active:hover,
button.list-group-item-success.active:focus {
  color: #fff;
  background-color: #2ecc71;
  border-color: #2ecc71
}

.list-group-item-info {
  color: #1a73e8;
  background-color: #d9edf7
}

a.list-group-item-info,
button.list-group-item-info {
  color: #1a73e8
}

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
  color: inherit
}

a.list-group-item-info:hover,
a.list-group-item-info:focus,
button.list-group-item-info:hover,
button.list-group-item-info:focus {
  color: #1a73e8;
  background-color: #c4e3f3
}

a.list-group-item-info.active,
a.list-group-item-info.active:hover,
a.list-group-item-info.active:focus,
button.list-group-item-info.active,
button.list-group-item-info.active:hover,
button.list-group-item-info.active:focus {
  color: #fff;
  background-color: #1a73e8;
  border-color: #1a73e8
}

.list-group-item-warning {
  color: #ff902b;
  background-color: #fcf8e3
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #ff902b
}

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
  color: inherit
}

a.list-group-item-warning:hover,
a.list-group-item-warning:focus,
button.list-group-item-warning:hover,
button.list-group-item-warning:focus {
  color: #ff902b;
  background-color: #faf2cc
}

a.list-group-item-warning.active,
a.list-group-item-warning.active:hover,
a.list-group-item-warning.active:focus,
button.list-group-item-warning.active,
button.list-group-item-warning.active:hover,
button.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #ff902b;
  border-color: #ff902b
}

.list-group-item-danger {
  color: #f05050;
  background-color: #f2dede
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #f05050
}

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
  color: inherit
}

a.list-group-item-danger:hover,
a.list-group-item-danger:focus,
button.list-group-item-danger:hover,
button.list-group-item-danger:focus {
  color: #f05050;
  background-color: #ebcccc
}

a.list-group-item-danger.active,
a.list-group-item-danger.active:hover,
a.list-group-item-danger.active:focus,
button.list-group-item-danger.active,
button.list-group-item-danger.active:hover,
button.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #f05050;
  border-color: #f05050
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3
}

// Table
.table-bordered {
  border: 1px solid #eee
}

.table>tbody>tr>td {
  vertical-align: middle;
}

.table>thead>tr>th,
.table>thead>tr>td,
.table>tbody>tr>th,
.table>tbody>tr>td,
.table>tfoot>tr>th,
.table>tfoot>tr>td {
  padding: 5px 10px;
  line-height: 1.528571429;
  border-top: 1px solid #eee
}

.table-responsive {
  overflow-x: auto;
  min-height: .01%;
}

#table-ext-1 th:nth-child(1) {
  width: 3%
}

#table-ext-1 th:nth-child(2) {
  width: 5%
}

#table-ext-1 th:nth-child(7) {
  width: 5%
}

#table-ext-1 th:nth-child(9) {
  width: 5%
}

#table-ext-2 th:nth-child(1) {
  width: 5%
}

#table-ext-2 th:nth-child(3) {
  width: 10%
}

.table-checkbox-w {
  width: 20px;
}

.table .checkbox {
  margin: 0 auto;
  width: 20px;
}

// Radio button

.c-checkbox,
.c-radio {
  margin-right: 4px
}

.c-checkbox *,
.c-radio * {
  cursor: pointer
}

.c-checkbox input,
.c-radio input {
  opacity: 0;
  position: absolute;
  margin-left: 0 !important
}

.c-checkbox span,
.c-radio span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: -20px;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #ccc;
  margin-right: 5px;
  text-align: center
}

.c-checkbox span:before,
.c-radio span:before {
  margin-left: 1px
}

.c-checkbox:hover span,
.c-radio:hover span {
  border-color: #5d9cec
}

.form-inline .c-checkbox span,
.form-inline .c-radio span {
  margin-left: 0
}

.c-checkbox.c-checkbox-rounded span,
.c-checkbox.c-radio-rounded span,
.c-radio.c-checkbox-rounded span,
.c-radio.c-radio-rounded span,
.c-radio span {
  border-radius: 500px
}

.c-checkbox span:before,
.c-radio span:before {
  position: absolute;
  inset: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle
}

.c-checkbox input[type=checkbox]:checked+span:before,
.c-checkbox input[type=radio]:checked+span:before,
.c-radio input[type=checkbox]:checked+span:before,
.c-radio input[type=radio]:checked+span:before {
  color: #fff;
  opacity: 1;
  transition: color .3s ease-out
}

.c-checkbox input[type=checkbox]:checked+span,
.c-checkbox input[type=radio]:checked+span,
.c-radio input[type=checkbox]:checked+span,
.c-radio input[type=radio]:checked+span {
  border-color: #5d9cec;
  background-color: #5d9cec
}

.c-checkbox input[type=radio]:checked+span,
.c-radio input[type=radio]:checked+span {
  background-color: #fff
}

.c-checkbox input[type=radio]:checked+span:before,
.c-radio input[type=radio]:checked+span:before {
  color: #5d9cec
}

.c-checkbox input[type=checkbox]:disabled+span,
.c-checkbox input[type=radio]:disabled+span,
.c-radio input[type=checkbox]:disabled+span,
.c-radio input[type=radio]:disabled+span {
  border-color: #ddd !important;
  background-color: #ddd !important
}

.c-checkbox input[type=radio]:disabled+span,
.c-radio input[type=radio]:disabled+span {
  background-color: #fff !important
}

.c-checkbox input[type=radio]:disabled+span:before,
.c-radio input[type=radio]:disabled+span:before {
  color: #ddd
}

.c-radio.c-radio-nofont span:before {
  content: "";
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  border-radius: 500px
}

.c-radio.c-radio-nofont input[type=radio]:checked+span:before {
  color: #fff;
  opacity: 1;
  transition: color .3s ease-out
}

.c-radio.c-radio-nofont input[type=radio]:checked+span {
  border-color: #5d9cec;
  background-color: #5d9cec
}

.c-radio.c-radio-nofont input[type=radio]:checked+span {
  background-color: #fff
}

.c-radio.c-radio-nofont input[type=radio]:checked+span:before {
  background-color: #5d9cec
}

.c-radio.c-radio-nofont input[type=radio]:disabled+span {
  border-color: #ddd !important;
  background-color: #ddd !important
}

.c-radio.c-radio-nofont input[type=radio]:disabled+span {
  background-color: #fff !important
}

.c-radio.c-radio-nofont input[type=radio]:disabled+span:before {
  background-color: #ddd
}


.radio,
.checkbox {
  position: relative;
  display: block;
  // margin-top: 10px;
  // margin-bottom: 10px
}

.radio label,
.checkbox label {
  min-height: 21px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer
}

.radio input[type=radio],
.radio-inline input[type=radio],
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px;

}

.radio+.radio,
.checkbox+.checkbox {
  margin-top: -5px
}

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: 400;
  cursor: pointer
}

.radio-inline+.radio-inline,
.checkbox-inline+.checkbox-inline {
  margin-top: 0;
  margin-left: 10px
}

input[type=radio][disabled],
input[type=radio].disabled,
fieldset[disabled] input[type=radio],
input[type=checkbox][disabled],
input[type=checkbox].disabled,
fieldset[disabled] input[type=checkbox],
.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled] .checkbox-inline,
.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed
}

// Labels
.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em
}

.label:empty {
  display: none
}

.btn .label {
  position: relative;
  top: -1px
}

a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer
}

.label-default {
  background-color: #e4eaec
}

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #c6d3d7
}

.label-primary {
  background-color: #5d9cec
}

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #2f80e7
}

.label-success {
  background-color: #2ecc71
}

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #25a25a
}

.label-info {
  background-color: #1a73e8
}

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #135cbc
}

.label-warning {
  background-color: #ff902b
}

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #f77600
}

.label-danger {
  background-color: #f05050
}

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #ec2121
}

// Tabs
.nav-tabs>li>a {
  font-weight: 700;
  color: #515253;
  background-color: #edf1f2;
  margin: 0;
  border: 1px solid #dde6e9;
  border-radius: 0;
  padding: 10px 20px
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
  color: inherit;
  border-bottom-color: #fff
}

.tab-content {
  padding: 10px 20px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: #dde6e9
}


.image-remove {
  top: -10px;
  left: 15px;
}

// Alerts
.alert-success {
  background-color: #2ecc71;
  border-color: #0000001a;
  color: #fff
}

.alert-success hr {
  border-top-color: #0000001a
}

.alert-success .alert-link {
  color: #e6e6e6
}

.alert-info {
  background-color: #1a73e8;
  border-color: #0000001a;
  color: #fff
}

.alert-info hr {
  border-top-color: #0000001a
}

.alert-info .alert-link {
  color: #e6e6e6
}

.alert-warning {
  background-color: #ff902b;
  border-color: #0000001a;
  color: #fff
}

.alert-warning hr {
  border-top-color: #0000001a
}

.alert-warning .alert-link {
  color: #e6e6e6
}

.alert-danger {
  background-color: #f05050;
  border-color: #0000001a;
  color: #fff
}

.alert-danger hr {
  border-top-color: #0000001a
}

.alert-danger .alert-link {
  color: #e6e6e6
}

// Offsidebar
@media only screen and (min-width: 768px) {
  .table-grid {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
  }
}
