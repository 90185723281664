/* ========================================================================
     Component: typo
 ========================================================================== */

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  src: url('/node_modules/source-sans/WOFF2/TTF/SourceSans3-Light.ttf.woff2') format('woff2'),
    // url('~/source-sans/OTF/SourceSans3-LightIt.otf') format('embedded-opentype')
    url('/node_modules/source-sans/WOFF/OTF/SourceSans3-Light.otf.woff') format('woff'),
    url('/node_modules/source-sans/OTF/SourceSans3-Light.otf') format('opentype'),
    url('/node_modules/source-sans/TTF/SourceSans3-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url('/node_modules/source-sans/WOFF2/TTF/SourceSans3-Regular.ttf.woff2') format('woff2'),
    // url('~/source-sans/OTF/SourceSans3-Regular.otf') format('embedded-opentype')
    url('/node_modules/source-sans/WOFF/OTF/SourceSans3-Regular.otf.woff') format('woff'),
    url('/node_modules/source-sans/OTF/SourceSans3-Regular.otf') format('opentype'),
    url('/node_modules/source-sans/TTF/SourceSans3-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  src: url('/node_modules/source-sans/WOFF2/TTF/SourceSans3-It.ttf.woff2') format('woff2'),
    // url('/node_modules/source-sans/EOT/SourceSansPro-It.eot') format('embedded-opentype'),
    url('/node_modules/source-sans/WOFF/OTF/SourceSans3-It.otf.woff') format('woff'),
    url('/node_modules/source-sans/OTF/SourceSans3-It.otf') format('opentype'),
    url('/node_modules/source-sans/TTF/SourceSans3-It.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  src: url('/node_modules/source-sans/WOFF2/TTF/SourceSans3-Semibold.ttf.woff2') format('woff2'),
    // url('~/source-sans/EOT/SourceSansPro-Semibold.eot') format('embedded-opentype'),
    url('/node_modules/source-sans/WOFF/OTF/SourceSans3-Semibold.otf.woff') format('woff'),
    url('/node_modules/source-sans/OTF/SourceSans3-Semibold.otf') format('opentype'),
    url('/node_modules/source-sans/TTF/SourceSans3-Semibold.ttf') format('truetype');
}


body {
  font-family: 'Sofia Sans', sans-serif;
  color: #464646;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}

.text-sm {
  font-size: 13px !important;
}
