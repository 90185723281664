.cursor-pointer {
  cursor: pointer;
}

.drag-item {
  /* background-color: #f1f1f1; */
  /* margin: 10px;
  padding: 10px; */
}

.drop-target p,
h3,
h2,
h1 {
  margin: 0;
}

.drop-target {
  max-height: 90vh !important;
  border: 2px solid #999;
  padding: 10px;
  overflow-y: auto;
  font-size: 10px;
  width: 816px;
  position: relative;
  /* display: table; */
}

.image-upload {
  display: none !important;
}


.drop-item {
  position: relative;
}

.drop-item i {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
  /* Initially hide the delete button */
}

/*
.drop-item:hover i {
  display: block;
} */

.delete-icon {
  color: red;
}

.right-sidebar {
  /* position: fixed; */
  /* top: 0;
    right: 0; */
  /* width: 200px; */
  height: 100%;
  background-color: #f1f1f1;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.right-sidebar .align-option {
  margin-bottom: 10px;
}

.right-sidebar .align-option input[type="radio"] {
  margin-right: 5px;
}

#increase-font-size,
#decrease-font-size {
  background-color: #f0f0f0;
  border: none;
  color: #333;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

#increase-font-size:hover,
#decrease-font-size:hover {
  background-color: #ddd;
}

#increase-font-size:active,
#decrease-font-size:active {
  background-color: #bbb;
}

#increase-font-size:focus,
#decrease-font-size:focus {
  outline: none;
}

.img-label {
  width: 100%;
}

.img-text {
  /* position: absolute;
    bottom: -35px; */
  border: 2px dashed #e5e7eb;
  border-radius: 5px;
  padding: 3px;
  text-align: center;
  font-size: 12px;
}

.display-none {
  display: none;
}

.dark-font-style {
  color: #000000;
  font-weight: bold;
}

.img-default {
  width: 200px;
  height: 100px;
  max-width: 100%;
}

.builderParentDiv {
  height: 85vh;
}

.input-border {
  border: 1px solid;
}

.gap-10 {
  gap: 10px;
}

.selected {
  border: 1px solid;
  border-color: rgb(226 232 240);
}

.f-size-32 {
  font-size: 32px;
}

.grid-row {
  display: flex !important;
  flex: wrap !important;
  border: 1px solid;
  border-color: rgb(226 232 240 / 67%);
}

.col-50 {
  width: 50% !important;
  max-width: 50% !important;
}

.col-70 {
  width: 70% !important;
  max-width: 70% !important;
}

.col-30 {
  width: 30% !important;
  max-width: 30% !important;
}

div:empty:not(:focus):before,
h1:empty:not(:focus):before,
p:empty:not(:focus):before,
h2:empty:not(:focus):before,
h3:empty:not(:focus):before {
  content: attr(data-placeholder);
  color: #999999;
}

.drop-target [data-handle].hover {
  border: 1px solid orange;
}

.dragover {
  border: 1px solid orange;
}

/* hr {
  border-top: 1px solid #000000 !important;
} */

.hr-element {
  height: 1px;
  background-color: rgb(0, 0, 0);
  border: none;
}

.element-box {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, .15);
  transition: all .2s ease 0s;
  transition-property: box-shadow, color;
  border: 1px solid rgba(0, 0, 0, .2);
  /* margin: 10px 2.5% 5px; */
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  width: 45%;
  min-width: 45px;
  padding: 1em;
  box-sizing: border-box;
  min-height: 90px;
  cursor: all-scroll;
  font-size: 11px;
  font-weight: lighter;
  text-align: center;
  display: flex;
}

.element-box:hover {
  color: #e3e3e3 !important;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .15) !important;
}

.flip-svg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

input[type="color" i] {
  appearance: auto;
  inline-size: 25px;
  block-size: 50px;
  cursor: default;
  box-sizing: border-box;
  border-width: 0;
  border-style: none;
  border-color: buttonborder;
  border-image: initial;
  padding: 0;
  cursor: pointer;
}

.swap-icon {
  position: absolute;
  right: 0;
  top: -27px;
  background: rgb(226 232 240);
  cursor: pointer;
  border: 1px solid rgb(226 232 240);
  border-bottom: none;
}

.above-icon {
  padding: 2px;
  border: 1px solid white;
}

.down-icon {
  padding: 2px;
  border: 1px solid white;
}

.help-text {
  color: darkgrey;
}

.hidden {
  display: none !important;
}
