/* Top-navbar component styles */

/* Variables */
.topnavbar {
  -webkit-backface-visibility: hidden;
  margin-bottom: 0;
  border-radius: 0;
  z-index: 1050;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

@media only screen and (min-width: 768px) {
  .topnavbar .navbar-header {
    width: 240px;
    text-align: center;
  }
  .topnavbar .navbar-header .navbar-brand {
    width: 100%;
  }
}

.topnavbar .navbar-header {
  background-color: transparent;
  background-image: linear-gradient(to right, #17a2b8, #5bc0de);
}

.topnavbar .navbar-header .navbar-brand {
  position: relative;
  z-index: 11;
  padding: 0;
}

.topnavbar .navbar-header .brand-logo,
.topnavbar .navbar-header .brand-logo-collapsed {
  display: block;
  margin: 0 auto;
}

.topnavbar .navbar-header .brand-logo img {
  margin: 0 auto;
}

.topnavbar .navbar-header .brand-logo {
  padding: 10px 15px;
}

.topnavbar .navbar-header .brand-logo-collapsed {
  display: none;
  padding: 6px 15px;
}

.topnavbar .navbar-nav > li > .navbar-text {
  color: #fff;
}

.topnavbar .navbar-nav > li > a,
.topnavbar .navbar-nav > .open > a {
  color: #fff;
}

.topnavbar .navbar-nav > li > a:hover,
.topnavbar .navbar-nav > li > a:focus,
.topnavbar .navbar-nav > .open > a:hover,
.topnavbar .navbar-nav > .open > a:focus {
  color: #f8f9fa;
}

.topnavbar .navbar-nav > .active > a,
.topnavbar .navbar-nav > .open > a {
  background-color: transparent;
}

.topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  font-size: 16px;
  line-height: 56px;
  color: #fff;
  padding: 0;
  @media only screen and (min-width: 768px) {
    color: #fff;
  }
}

@media only screen and (max-width: 767px) {
  .topnavbar .navbar-text {
    margin: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-toggle {
    position: absolute !important;
    top: 5px;
    right: 0;
    z-index: 3001;
  }
}

/* Navbar wrapper */
.nav-wrapper {
  padding: 0 15px;
  background-color: transparent;
}

.nav-wrapper .nav.navbar-nav {
  float: left;
}

.nav-wrapper .nav.navbar-nav.navbar-right {
  float: right;
}

.nav-wrapper .nav.navbar-nav > li {
  position: static;
  float: left;
}

.nav-wrapper .navbar-nav .open .dropdown-menu {
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

/* Navbar search form */
.topnavbar .navbar-form {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  height: 56px;
  z-index: 9001;
  transition: all 0.3s;
  border: 0;
  border-bottom: 1px solid #e1e2e3;
}

.topnavbar .navbar-form.open {
  top: 0;
}

.topnavbar .navbar-form .form-group {
  height: 100%;
  width: 100%;
}

.topnavbar .navbar-form .form-control {
  height: 100%;
  border: 0;
  border-radius: 0;
  width: 100%;
}

.topnavbar .navbar-form .form-control-feedback {
  height: 30px;
  cursor: pointer;
  top: 50%;
  margin-top: -15px;
  line-height: 30px;
  margin-right: 10px;
  color: #c1c2c3;
  font-size: 1.5em;
  pointer-events: auto;
}

@media only screen and (min-width: 768px) {
  .topnavbar .navbar-form {
    left: 240px;
  }
}

/* Navbar desktop styles */
@media only screen and (min-width: 768px) {
  .topnavbar {
    border: 0;
    background-color: #17a2b8;
  }
  .topnavbar .navbar-header {
    background-image: none;
  }
  .topnavbar .navbar-nav > a {
    box-shadow: 0 0 0 #000 inset;
    transition: all 0.2s;
  }
  .topnavbar .navbar-nav > .open > a,
  .topnavbar .navbar-nav > a:hover {
    // box-shadow: 0 -3px 0 #007bff inset;
  }
  .topnavbar .navbar-nav > .open > a,
  .topnavbar .navbar-nav > .open > a:hover,
  .topnavbar .navbar-nav > .open > a:focus {
    background-color: transparent;
  }
  .topnavbar .navbar-nav > li > .navbar-text {
    color: #fff;
  }
  .topnavbar .navbar-nav > li > a,
  .topnavbar .navbar-nav > .open > a {
    color: #fff;
  }
  .topnavbar .navbar-nav > li > a:hover,
  .topnavbar .navbar-nav > li > a:focus,
  .topnavbar .navbar-nav > .open > a:hover,
  .topnavbar .navbar-nav > .open > a:focus {
    color: #f8f9fa;
  }
  .topnavbar .navbar-nav > .active > a,
  .topnavbar .navbar-nav > .open > a {
    background-color: transparent;
  }
  .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
    position: static;
  }
}

@media only screen and (min-width: 768px) {
  .nav-wrapper {
    clear: both;
    position: relative;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    z-index: 10;
  }
  .nav-wrapper .nav.navbar-nav > li {
    position: relative;
  }
  .nav-wrapper .navbar-nav .open .dropdown-menu {
    left: auto;
    right: auto;
  }
  .nav-wrapper .navbar-nav.navbar-right .open .dropdown-menu {
    left: auto;
    right: 0;
  }
}

@media only screen and (min-width: 768px) {
  .aside-collapsed .topnavbar .navbar-header .brand-logo {
    display: none;
  }
  .aside-collapsed .topnavbar .navbar-header .brand-logo-collapsed {
    display: block;
  }
  .aside-collapsed .topnavbar .navbar-header {
    width: 56px;
  }
  .aside-collapsed .topnavbar .navbar-form {
    left: 56px;
  }
}

@media only screen and (min-width: 768px) {
  .aside-collapsed-text .topnavbar .navbar-header .brand-logo {
    display: none;
  }
  .aside-collapsed-text .topnavbar .navbar-header .brand-logo-collapsed {
    display: block;
  }
  .aside-collapsed-text .topnavbar .navbar-header {
    width: 120px;
  }
  .aside-collapsed-text .topnavbar .navbar-form {
    left: 120px;
  }
}
