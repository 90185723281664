@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto
  }

  .modal-content {
    box-shadow: 0 5px 15px #00000080
  }

  .modal-sm {
    width: 300px
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 80%
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 30px auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 80%;
  }
}

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .2;
  filter: alpha(opacity=20);
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .5;
  filter: alpha(opacity=50);
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 15px;
}
