.stats {
  margin-top: 15px 0px;

  .badge {
    border-radius: 10%;
  }
}

.cp {
  cursor: pointer;
}

.main-wrapper {
  margin: -20px;
  height: calc(100vh - 115px);
  background: #fff;
  display: flex;
  flex-flow: column;
  align-items: stretch
}

.split-wrapper {
  display: flex;
  flex: 1 1 auto;

  .split-left {
    display: flex;
    flex-flow: column;
    flex: 0 1 auto;
    min-height: 100%;
    max-width: 380px;
    min-width: 380px;

    .split-left-1 {
      ul {
        li {
          line-height: 35px;
        }
      }
    }
  }

  .split-right {
    width: 100%;
    display: flex;
  }
}

.col-flex {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.row-flex {
  display: flex;
  flex-flow: row;
  height: 100%;
}



::-webkit-scrollbar {
  height: 20px;
  width: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border: 3px solid transparent;
  border-radius: 1ex;
  background-clip: content-box;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.modal-backdrop {
  z-index: 998
}

.modal {
  z-index: 999
}

.m0 {
  margin: 0
}

.mtop0 {
  margin-top: 0
}

.panel .table {
  border: 1px solid #eee
}

.wrapper>footer {
  position: fixed;
  z-index: 2 !important;
  background: white;
}

// .layout-fixed .wrapper .topnavbar-wrapper {
//     z-index: 111;
// }

// .wrapper > .aside, .wrapper > footer {
//     z-index: 110
// }

.output {
  padding: 15px;
  background-color: #eee;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
}

.pagination {
  margin: 10px 0
}

.main-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
}

.split-wrapper {
  height: 100%;
}

.fr-toolbar.fr-top.fr-sticky {
  position: relative;
}

.required-field {
  color: red
}

.form-control-feedback {
  top: 10px
}

.green {
  color: #27ae60
}

.selectable {
  cursor: pointer;
}

.select-location {
  font-size: 18px
}

// .collapse {
//   /* setup transition however you like */
//   -webkit-transition: max-height 0.5s ease;
//   -moz-transition: max-height 0.5s ease;
//   -o-transition: max-height 0.5s ease;
//   -ms-transition: max-height 0.5s ease;
//   transition: max-height 0.5s ease;

//   /* override display, overflow and visibility */
//   display: block !important;
//   overflow: hidden !important;
//   visibility: visible !important;

//   max-height: 0;

//   &.in {
//     /* set max-height to something high so that
//     it can fit whatever you have inside your collapsible tag.
//     Not too high though, because the animation time will be based
//     on this value, so if it's too high the animation will be
//     very fast */
//     max-height: 1000px;
//   }
// }

.display-flex {
  display: flex;
}

.display-inline-flex {
  display: inline-flex;
}

.justify-center {
  justify-content: center;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.valign-middle {
  vertical-align: middle;
}

.align-items-center {
  align-items: center;
}

.label {
  line-height: inherit;
}

.px-xs {
  padding-left: 5px;
  padding-right: 5px;
}

.px-sm {
  padding-left: 10px;
  padding-right: 10px;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2 {
  padding-left: 0.2rem;
}

.px-5 {
  padding-left: 0.5rem;
}

.py-xs {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.py-0 {
  padding-bottom: 0;
  padding-top: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.pb-xs {
  padding-bottom: 5px;
}

.pb-sm {
  padding-bottom: 10px;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pt-xs {
  padding-top: 5px;
}

.pt-sm {
  padding-top: 10px;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-xs {
  margin: 0 0.2rem !important
}

.mx-sm {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-2 {
  margin: 0 1rem
}

.my-xs {
  margin: 0.4rem 0 !important
}

.my-sm {
  margin-top: 0.8rem !important;
  margin-bottom: 0.8rem !important
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-2 {
  margin: 20px 0 !important
}

.mt-xs {
  margin-top: 0.3rem;
}

.mt-sm {
  margin-top: 0.7rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-negative-10 {
  margin-top: -10px;
}

.mt-negative-5 {
  margin-top: -5px;
}

.mb-sm {
  margin-bottom: 0.8rem !important;
}

.mb-md {
  margin-bottom: 15px !important;
}

.ms-xs {
  margin-left: 0.2rem;
}

.ms-sm {
  margin-left: 0.8rem;
}

.br-3 {
  border-radius: 3px;
}

.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 10px;
}

// text size
.text-xs1 {
  font-size: 0.6rem !important;
}

.text-xs {
  font-size: 0.7rem !important;
}

.text-m {
  font-size: 1rem !important;
}

.text-m1 {
  font-size: 1.2rem !important;
}

.text-xl {
  font-size: 2rem !important;
}

.text-13p {
  font-size: 13px !important;
}

.tab-content {
  padding: 10px 0px !important;
}

.wrapper>footer {
  padding: 10px;
  height: auto;
}

.panel-dark>.panel-heading {
  background-color: #4a547a;
}

/** Custom media queries */

@media (min-width: 1921px) {
  .col-2xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.cancel_btn:hover {
  color: #fff;
}

.cancel_btn:hover svg {
  fill: rgb(255, 255, 255);
}

app-root,
.wrapper {
  display: block;
}

// Main wrapper
// -----------------------------
.wrapper {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow-x: hidden;
}

html,
body,
app-root {
  height: 100%;
}

body {
  font-family: Sofia Sans, sans-serif;
  font-size: 14px;
  line-height: 1.528571429;
  color: #515253;
  background-color: #fff
}

app-root,
.wrapper {
  display: block
}
