.setting-color {
  padding: 0 5px;
}

.setting-color > label {
  display: block;
  position: relative;
  margin: 0 10px;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, .1);
  cursor: pointer;
}

.setting-color > label:first-child {
  margin-left: 0;
}

.setting-color > label:last-child {
  margin-right: 0;
}

.setting-color > label > .color {
  display: block;
  height: 18px;
}

.setting-color > label > .split::after {
  content: "";
  display: table;
  clear: both;
}

.setting-color > label > .split > .color {
  display: block;
  height: 37.5px;
}

.setting-color > label > .split > .color:first-child {
  float: left;
  width: 70%;
}

.setting-color > label > .split > .color:last-child {
  float: right;
  width: 30%;
}

.setting-color > label > .icon-check {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -20px;
  margin-left: -10px;
  text-align: center;
  font-size: 1.33333333em;
  vertical-align: -15%;
  color: #fff;
  opacity: 0;
}

.setting-color > label > input[type="radio"] {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.setting-color > label > input[type="radio"]:checked + .icon-check {
  opacity: 1 !important;
}
