// /* You can add global styles to this file, and also import other style files */

//== Application
@import "./app/shared/styles/app.scss";

// // @import '~ngx-toastr/toastr.css';
// @import '../node_modules/ngx-toastr/toastr.css';
// @import '../node_modules/ngx-toastr/toastr-bs4-alert.scss';

@import '~font-awesome/css/font-awesome.css';
@import '~simple-line-icons/css/simple-line-icons.css';
@import '../node_modules/@nubebytes/angular-archwizard/styles/archwizard.css';

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1,
h2,
h3,
h4 {
  font-weight: 700
}

html {
  direction: ltr;
  height: 100%;
  touch-action: manipulation
}

html,
body,
app-root {
  height: 100%
}

app-root,
.wrapper {
  display: block
}

.nav-tabs>li:first-child>a {
  border-top-left-radius: 0.8rem !important;
  // border-top-right-radius: 0.8rem !important;
}

.nav-tabs>li:last-child>a {
  // border-top-left-radius: 0.8rem !important;
  border-top-right-radius: 0.8rem !important;
}

// .nav-tabs>li.active>a {
//     padding: 7px 20px;
// }

// .nav-tabs>li.active>a.active {
//     border-bottom: 0;
// }

h3 {
  margin-top: 0;
}

.table>thead>tr>th {
  padding: 5px 10px;
  background: #f4f4f4;
  color: #464646;
}

.form-control-feedback {
  line-height: 1 !important;
}

.toast-container>div {
  opacity: 1;
}

select {
  height: auto !important;
  -webkit-appearance: listbox !important;
}

.modal-dialog {
  transform: translate(0, 0) !important;
}

.modal-open .modal {
  background: #00000077;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10.5px
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-bottom: 0
}

.list-unstyled {
  padding-left: 0;
  list-style: none
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px
}

.list-inline>li {
  display: inline-block;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-800 {
  width: 800px !important;
  max-width: 800px !important;
}

.apexcharts-tooltip {
  line-height: 1.2px !important;
}
